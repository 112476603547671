import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { Button, SearchInput, Typography,Dropdown,Avatar } from "@presto-labs-ai/design-system-new";
import assistant from "../../assets/icons/starSearch.svg";
import filledWhiteStar from "../../assets/icons/filledWhiteStar.svg";
import {
  toggleSearch,
  getSearchResults,
  setSearchLoaded,
} from "../../actions/searchActions";
import { formatDate } from "../../utils";
import documentIcon from "../../assets/icons/documentIcon.svg";
import actionIcon from "../../assets/icons-sidenav/actions.svg";
import sortIcon from "../../assets/sortIcon.svg"
import dateIcon from "../../assets/calIcon.svg"
import personIcon from "../../assets/userIcon.svg"
import chevronDown from "../../assets/icons/chevronDown.svg";
// import _ from "underscore"

const Search = ({
  showSearch,
  toggleSearch,
  searchLoaded,
  searchResultsRaw,
  getSearchResults,
  org_id,
  workspace_id,
  board_id,
  setSearchLoaded,
}) => {
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const { boardMembers } = useSelector(state => state.board);
  const [showFilters,setShowFilters] = useState(true);
  const [sortFilterVal,setSortFilterVal] = useState("");
  const [dateFilterVal,setDateFilterVal] = useState("");
  const [personFilterVal,setPersonFilterVal] = useState("");
  useEffect(() => {
    const handleEsc = (event) => {
      if (
        event.key === "Escape" ||
        ((event.ctrlKey || event.metaKey) && event.key === "k")
      ) {
        toggleSearch(false);
      }
    };

    if (showSearch) {
      document.addEventListener("keydown", handleEsc);
      document.getElementById("searchInput")?.focus();
    }

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [showSearch, toggleSearch]);
  const [searchQuery, setSearchQuery] = useState("");

  const resultIcon = (entity_type) => {
    switch (entity_type) {
      case "DOCUMENT":
        return documentIcon;
      case "ACTION":
        return actionIcon;
      default:
        return null;
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedSearch = useRef(
    
    debounce((value,personFilterVal,dateFilterVal,sortFilterVal) => {
      let requestObj = {
        query: value,
        org_id: org_id,
        workspace_id: workspace_id,
        board_id: board_id,
        filters: {
          entity_type: ["ACTION","DOCUMENT"],
          file_type: [],
          date: dateFilterVal?.id ? dateFilterVal.id : "last_7_days",
          event_by: personFilterVal?.id ? [personFilterVal?.id] : [],
        }
      };
      getSearchResults(requestObj);
    }, 1000)
  ).current;

  const onSearchQueryChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setSearchLoaded(false);
    debouncedSearch(value,personFilterVal,dateFilterVal,sortFilterVal);
  };
  const clearSearchQuery = () => {
    setSearchQuery("");
    document.getElementById("searchInput").value = ""; // UseRef is not Working
    document.getElementById("searchInput")?.focus();
  };
  const handleBackdropClick = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      toggleSearch(false);
      console.log("Working", showSearch);
    }
  };
  const redirectToAssistant = () => {
    navigate("/assistant", { state: { searchQuery: searchQuery } });
    toggleSearch(false);
  };
  const filterButtonClick = () => {
    setShowFilters(!showFilters)
  }
  console.log(sortFilterVal,dateFilterVal,personFilterVal);

  const applySort = (results,type) => {
    switch(type){
      case "oldest":
        return results.sort((a,b) => new Date(a.created_at) - new Date(b.created_at));
      case "newest":
        return results.sort((a,b) => new Date(b.created_at) - new Date(a.created_at));
      case "ascending":
        return results.sort((a,b) => a.event_title.localeCompare(b.event_title));
      case "descending":
        return results.sort((a,b) => b.event_title.localeCompare(a.event_title));
      default:
        return results;
    }
  }

  

  const SortFilter = () => {
    const sortDropdownOptions = [{ id: "oldest", name: "Oldest" },
      { id: "newest", name: "Newest" },
      { id: "ascending", name: "Ascending" },
      { id: "descending", name: "Descending"}]

    return (<div className="z-[100]">
      <Dropdown
        dropdownPlaceholder={
          <div className="flex items-center gap-2 bg-bg-primary hover:bg-bg-primary_hover px-4 py-2 rounded-full">
            <img src={sortIcon} alt="sortIcon" />
            <span className="text-text-secondary text-sm font-semibold">
              {sortDropdownOptions.find(
                (item) => item.id === sortFilterVal?.id
              )?.name || "Sort"}
            </span>
            <img
              src={chevronDown}
              alt="DownArrow"
              className=" mt-0.5"
            />
          </div>
        }
        data={sortDropdownOptions.map((item) => ({
          id: item.id,
          render: () => (
            <span className="block w-full text-text-primary  px-4 py-2 text-left text-sm cursor-pointer">
              {item.name}
            </span>
          ),
        }))}
        selected={sortFilterVal}
        onChange={(id) => {
          setSortFilterVal(id);
        }}
        type="check"
        variant="ghost"
        placement="bottom-left"
        customDropdownClass="absolute z-10 mt-2 max-h-56 w-[270px] origin-top-right bg-bg-primary border-2 border-border-secondary rounded-3xl overflow-y-auto scrollbar-thin scrollbar-corner-bg-quaternary scrollbar-thumb-bg-quaternary scrollbar-track-bg-primary scrollbar-thumb-rounded-full scrollbar-track-rounded-full right-0"
      />
    </div>)
  }

  const DateFilter = () => {
    const dateDropdownOptions = [{ id: "anytime", name: "Any time" },
      { id: "today", name: "Today" },
      { id: "yesterday", name: "Yesterday" },
      { id: "last_7_days", name: "Last 7 days"},
      { id: "last_30_days", name: "Last 30 days" },
      { id: "last_3_months", name: "Last 3 months" },
      { id: "last_12_months", name: "Last 12 months" }];
    return (
      <div className="z-[52]">
        <Dropdown
        dropdownPlaceholder={
          <div className="flex items-center gap-2 bg-bg-primary hover:bg-bg-primary_hover px-4 py-2 rounded-full">
            <img src={dateIcon} alt="dateIcon" />
            <span className="text-text-secondary text-sm font-semibold">
              {dateDropdownOptions.find(
                (item) => item.id === dateFilterVal?.id
              )?.name || "Date"}
            </span>
            <img
              src={chevronDown}
              alt="DownArrow"
              className=" mt-0.5"
            />
          </div>
        }
        data={dateDropdownOptions.map((item) => ({
          id: item.id,
          render: () => (
            <span className="block w-full text-text-primary  px-4 py-2 text-left text-sm cursor-pointer">
              {item.name}
            </span>
          ),
        }))}
        selected={dateFilterVal}
        onChange={(id) => {
          setDateFilterVal(id);
          setSearchLoaded(false);
          debouncedSearch(searchQuery,personFilterVal,id,sortFilterVal);
        }}
        type="check"
        variant="ghost"
        placement="bottom-left"
        customDropdownClass="absolute z-10 mt-2 max-h-56 w-[270px] origin-top-right bg-bg-primary border-2 border-border-secondary rounded-3xl overflow-y-auto scrollbar-thin scrollbar-corner-bg-quaternary scrollbar-thumb-bg-quaternary scrollbar-track-bg-primary scrollbar-thumb-rounded-full scrollbar-track-rounded-full left-0"
      />
      </div>
    )
  }

  const PersonFilter = () => {
    let personDropdownOptions = boardMembers.map(member => ({id:member.uid,name:member.fullname,profile_image:member.profile_image}));
    console.log(boardMembers);
    let selectedPerson = personDropdownOptions.find(
      (item) => item.id === personFilterVal?.id
    )
    return (
      <div className="z-[52]">
        <Dropdown
        dropdownPlaceholder={
          <div className="flex items-center gap-2 bg-bg-primary hover:bg-bg-primary_hover px-4 py-2 rounded-full">
            <img src={personIcon} alt="personIcon" />
            <span className="text-text-secondary text-sm font-semibold">
              {selectedPerson ? ("Created by: "+ selectedPerson?.name) : "Created by"}
            </span>
            <img
              src={chevronDown}
              alt="DownArrow"
              className="mt-0.5"
            />
          </div>
        }
        data={personDropdownOptions.map((item) => ({
          id: item.id,
          render: () => (
            <div className="flex items-center gap-2 w-full text-text-primary  px-4 py-2 text-left text-sm cursor-pointer">
              <Avatar size="sm" fullName={item.name} imageUrl={item?.profile_image}/>
              <span className="">
              {item.name}
            </span>
              </div>
            
          ),
        }))}
        selected={personFilterVal}
        onChange={(id) => {
          setPersonFilterVal(id);
          setSearchLoaded(false);
          debouncedSearch(searchQuery,id,dateFilterVal,sortFilterVal);
        }}
        type="check"
        variant="ghost"
        placement="bottom-left"
        customDropdownClass="absolute z-10 mt-2 max-h-56 w-[300px] origin-top-right bg-bg-primary border-2 border-border-secondary rounded-3xl overflow-y-auto scrollbar-thin scrollbar-corner-bg-quaternary scrollbar-thumb-bg-quaternary scrollbar-track-bg-primary scrollbar-thumb-rounded-full scrollbar-track-rounded-full left-0"
      />
      </div>
    )
  }


  const filtersDiv = (
    <div className={`h-[58px] flex items-center bg-bg-primary p-5 ${searchQuery ? "" : "rounded-b-3xl"} ${showFilters ? "block" : "hidden"}`}>
      <div className="flex justify-between w-full">
       <div className="flex gap-2">{DateFilter()} {PersonFilter()}</div>
        <div>{SortFilter()}</div>
      </div>
    </div>
  );
  const SkeletonSearch = () => (
    <div className="animate-custom-pulse bg-bg-quaternary h-[50px] w-full rounded-md"></div>

  );

  const searchResults = applySort(searchResultsRaw,sortFilterVal?.id);
  console.log("searchLoaded",searchLoaded);
  const resultsDiv =
    searchQuery && searchLoaded && searchResults && searchResults.length > 0 ? (
      <div className="bg-bg-primary px-5 rounded-b-3xl max-h-[400px] overflow-y-auto">
        <div>
          <Typography
            fontSize="sm"
            fontWeight="semibold"
            fontColor="tertiary"
            fontStyle="normal"
          >
            Actions
          </Typography>
          <div
            className="flex items-center py-2 hover:bg-bg-primary_hover rounded-2xl px-3 cursor-pointer"
            onClick={() => {
              redirectToAssistant();
            }}
          >
            <div className="bg-bg-tertiary h-[48px] w-[48px] p-3 rounded-full flex items-center justify-center">
              <img
                className=" h-[20px] stroke-blue-500"
                src={assistant}
                alt=""
              ></img>
            </div>

            <div className="pl-3">
              <Typography fontSize="sm" fontWeight="medium" fontStyle="normal">
                Ask Assistant {searchQuery && '"' + searchQuery + '"'}
              </Typography>
            </div>
          </div>
        </div>
        <div className="py-1">
          <Typography
            fontSize="sm"
            fontWeight="semibold"
            fontColor="tertiary"
            fontStyle="normal"
          >
            Results
          </Typography>
          {searchResults.map((result) => {
            return (
              <div
                className="flex items-center py-2 hover:bg-bg-primary_hover rounded-2xl px-3 cursor-pointer"
                onClick={() => {
                  let path = `/tasks?site=${result.workspace_id}&board==${result.board_id}&ticket=${result.entity_id}`;
                  if(result.entity_type === "DOCUMENT"){
                    path = `/spaces?folder=${result?.entity_details?.parent_id}&document=${result?.entity_id}`;
                  }
                  navigate(path);
                  toggleSearch(false);
                }}
              >
                <Avatar size="lg" border="none" fullName={result.event_title} icon={resultIcon(result.entity_type)}/>

                <div className="pl-3 flex justify-between w-full">
                  <div>
                  <Typography
                    fontSize="sm"
                    fontWeight="medium"
                    fontStyle="normal"
                  >
                    {result.event_title}
                  </Typography>
                  <Typography
                    fontSize="xs"
                    fontWeight="regular"
                    fontStyle="normal"
                    fontColor="tertiary"
                  >
                    {`${result.action_slug ? result.action_slug : ""} (${result.entity_type})`}
                  </Typography>
                    </div>
                    <div className="flex items-center gap-2">
                      <Typography fontSize="xs" fontWeight="regular" fontStyle="normal" fontColor="tertiary">{formatDate(result.created_at)}</Typography>
                    </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    ) : searchQuery &&
      searchLoaded &&
      searchResults &&
      searchResults.length === 0 ? (
      <div className="flex flex-col">
        <div className="h-[255px] flex items-center justify-center bg-bg-primary p-5 rounded-b-3xl">
        <div className="flex flex-col items-center justify-between h-[84px]">
          <Typography fontSize="base" fontWeight="semibold" fontStyle="normal">
            No results found
          </Typography>

          <Button
            size="md"
            color="gradient"
            variant="filled"
            shape="circle"
            onClick={() => {}}
          >
            <div
              className="item-center flex justify-center cursor-pointer"
              onClick={() => {
                redirectToAssistant();
              }}
            >
              <img
                src={filledWhiteStar}
                alt="filledWhiteStar"
                className="mr-2"
              />
              Ask assistant
            </div>
          </Button>
        </div>
      </div>
      </div>
    ) : 
      ((searchQuery && !searchLoaded )? <div className="h-[255px] flex flex-col items-center justify-center bg-bg-primary p-5 rounded-b-3xl gap-3">
        <SkeletonSearch />
        <SkeletonSearch />
        <SkeletonSearch />
      </div> : null)
    const containerDiv = (
      <div>
        {filtersDiv}
        {resultsDiv}
      </div>
    );

  return (
    <>
      <div
        className="fixed inset-0 bg-bg-overlay flex items-center justify-center p-4 z-[100] backdrop-filter backdrop-blur-sm"
        onClick={handleBackdropClick}
      >
        <div className="w-[720px]" ref={modalRef}>
          <SearchInput
            placeholder="Search or ask questions in this workspace..."
            value={searchQuery}
            onChange={onSearchQueryChange}
            onClearButtonClick={clearSearchQuery}
            containerDiv={containerDiv}
            id="searchInput"
            autoComplete="off"
            showActionButton={true}
            onActionButtonClick={filterButtonClick}
            showRoundedBottom = {!showFilters && !resultsDiv}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  showSearch: state.search.showSearch,
  searchResultsRaw: state.search.searchResults,
  searchLoaded: state.search.searchLoaded,
  org_id: state.appState.org_id,
  workspace_id: state.appState.workspace_id,
  board_id: state.appState.board_id,
});

const mapDispatchToProps = {
  toggleSearch: toggleSearch,
  getSearchResults: getSearchResults,
  setSearchLoaded: setSearchLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
