import logo from "../../assets/icons/prestoLogo.svg";

const SplashScreen = () => {

  return (
    <>
      <div className="flex items-center justify-center w-screen h-screen bg-bg-primary">
        <div className="flex items-center justify-center">
          <img src={logo} alt="logo" className="w-60" />
        </div>
      </div>
    </>
  );
};
export default SplashScreen;
