import React, { useEffect } from 'react';
import { Modal,Avatar,Button} from '@presto-labs-ai/design-system-new'
import { connect } from 'react-redux';
import { showCustomModalAction, hideCustomModalAction } from '../../actions/modalActions';
import EditIcon from "../../assets/icons/editIcon.svg";

const CustomModal = ({customModalData,hideCustomModal}) => {
    const getSystemTheme = () => {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          return "dark";
        } else {
          return "light";
        }
      };
 const theme = localStorage.getItem("theme") === "system" ? getSystemTheme() : localStorage.getItem("theme");
  const { title, description, icon, buttonText,onSubmit,onClose,children,buttonColor,isDisabled = false } = customModalData;

  const iconMapping = {
    edit : {
        light : EditIcon,
        dark : EditIcon
    }   
  }

  const onCloseHandler = () => {
    hideCustomModal();
    onClose && onClose();
  }

  const onSubmitHandler = () => {
    onSubmit && onSubmit();
    onCloseHandler();
  }


  const background = {
    edit : "bg-bg-tertiary"
  }

  useEffect(() => {
    // This will ensure the modal content updates when customModalData changes
  }, [customModalData]);

  return (
    <Modal
      onClose={onCloseHandler}
      widthVariant="default"
      headerIconUrl={<Avatar  size="lg" border="none" background={background[icon]} icon={iconMapping[icon][theme]}/>}
      headerTitle={title}
      headerDescription={description}
    >
      <div className="flex flex-col w-full px-2 space-y-2">
        {children}
        <div className="grid grid-cols-2 items-center pt-4 w-full gap-x-5">
          <Button
            color="secondary"
            onClick={onCloseHandler}
            shape="circle"
          >
            Cancel
          </Button>

          <Button
            color={buttonColor}
            onClick={onSubmitHandler}
            shape="circle"
            disabled={isDisabled}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  customModalData: state.modal.customModalData,
  showCustomModal: state.modal.showCustomModal,
});

export default connect(mapStateToProps, {
  showCustomModal: showCustomModalAction,
  hideCustomModal: hideCustomModalAction,
})(CustomModal);
