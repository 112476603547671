import { call } from 'redux-saga/effects';
import axios from 'axios';
const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* updateOrganisationSaga(params) {
  try {

    const updateOrganisationResponse = yield call(axios.put,
      `${env.newBaseURLDev}/organization/organization/${params.params.id}/`,
      params.params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (updateOrganisationResponse.status === 200) {

        let organisationDetails = JSON.parse(localStorage.getItem("new_org_details"));
        organisationDetails.country = params.params.country;
        organisationDetails.org_name = params.params.org_name;
        organisationDetails.address_line_1 = params.params.address_line_1;
        organisationDetails.address_line_2 = params.params.address_line_2;
        organisationDetails.city = params.params.city;
        organisationDetails.pincode = params.params.pincode;
        organisationDetails.vat_id = params.params.vat_id;
        localStorage.setItem("new_org_details", JSON.stringify(organisationDetails));

        window.showNotification({
            id: Date.now(),
            message: "Organisation updated successfully.",
            variant: "success",
            autoHide: true,
          });
    }
  } catch (e) {

  }
}