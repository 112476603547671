import axios from "axios";
import { put } from "redux-saga/effects";
import {
  ERROR_MAN_HOURS,
  HIDE_LOADER,
  POST_MAN_HOURS,
  SET_ACCESS_SITES,
  SET_ALL_SITES,
  SET_COMBINED_SITES,
  SET_DASHBOARD_COUNT,
  SET_MAN_HOURS,
  SET_SELECTED_WORKSPACE,
  // SET_WORKSPACE_MAN_HOURS,
  SHOW_TOAST,
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* addUserToProjectSaga(params) {
  try {
    let { selectedUser, currentSite, accessLevel } = params.params;

    let addUserToProjectResponse = yield axios.post(
      `${env.baseURLDev}/site/add-user`,
      {
        site_id: currentSite.value,
        user_id: selectedUser.value,
        access: Number(accessLevel.value),
      },
      {
        headers: {
          authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (addUserToProjectResponse.status === 201) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "User has been invited to the site.", toastType: 1 },
      });
    }
  } catch (e) {
    if (e.message.includes("400")) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "User already invited to site.", toastType: 0 },
      });
    }

    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}

// export function* addNewSiteSaga(params) {
//   try {
//     if (params.params.newSite.newSite.trim().length < 6) {
//       yield put({
//         type: SHOW_TOAST,
//         data: {
//           message: "Site name should be at least 6 characters.",
//           toastType: 0,
//         },
//       });
//     } else {
//       let addNewSiteResponse = yield axios.post(
//         `${env.baseURLDev}/site/create`,
//         {
//           newSite: params.params.newSite.newSite,
//           avatar: params.params.newSite.avatar,
//         },
//         {
//           headers: {
//             authorization: `${localStorage.getItem("token")}`,
//           },
//         }
//       );

//       if (addNewSiteResponse.status === 201) {
//         yield put({
//           type: SHOW_TOAST,
//           data: { message: "Your site has been added.", toastType: 1 },
//         });

//         setTimeout(() => {
//           window.location = `/tasks?site=${addNewSiteResponse.data.data.siteSlug}&board=${addNewSiteResponse.data.data.boardSlug}`;
//         }, 5000);
//       }
//     }
//   } catch (e) {
//     if (e.message.includes("400")) {
//       yield put({
//         type: SHOW_TOAST,
//         data: {
//           message: "Site already exists, please use another name.",
//           toastType: 0,
//         },
//       });
//     }

//     if (e.message.includes("500")) {
//       yield put({
//         type: SHOW_TOAST,
//         data: {
//           message: "Something went wrong, please try again later.",
//           toastType: 0,
//         },
//       });
//     }
//   }
// }

export function* addNewSiteSaga(params) {
  try {
    if (params.params.newSite.newSite.trim().length < 6) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Site name should be at least 6 characters.",
          toastType: 0,
        },
      });
    } else {
      let addNewSiteResponse = yield axios.post(
        `${env.newBaseURLDev}/workspaces/action/`,
        {
          newSite: params.params.newSite.newSite,
          avatar: params.params.newSite.avatar,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (addNewSiteResponse.status === 201) {
        yield put({
          type: SHOW_TOAST,
          data: { message: "Your site has been added.", toastType: 1 },
        });

        setTimeout(() => {
          window.location = `/tasks?site=${addNewSiteResponse.data.data.siteSlug}&board=${addNewSiteResponse.data.data.boardSlug}`;
        }, 5000);
      }
    }
  } catch (e) {
    if (e.message.includes("400")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Site already exists, please use another name.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}



export function* getAllSitesSaga() {
  try {
    // let getAllSitesResponse = yield axios.get(
    //   `${env.baseURLDev}/site/get-all`,
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    // if (getAllSitesResponse.status === 200) {
    //   console.log(getAllSitesResponse.data.data, "old data");
    //   yield put({ type: HIDE_LOADER });
    //   yield put({ type: SET_ALL_SITES, data: getAllSitesResponse.data.data });
    // }

    let getAllSitesResponseNew = yield axios.get(
      `${env.newBaseURLDev}/workspaces/workspace/get_workspace_by_uid`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (getAllSitesResponseNew.status === 200) {
      yield put({ type: HIDE_LOADER });
      yield put({ type: SET_ALL_SITES, data: getAllSitesResponseNew.data });
      yield put({
        type: SET_SELECTED_WORKSPACE,
        data: getAllSitesResponseNew.data[0],
      });
    }
  } catch (e) {
    yield put({ type: HIDE_LOADER });
    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }

    if (e.response.status === 401) {
      console.error("Logging out..", e.response.data.error);
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Session expired...",
          toastType: 0,
        },
      });
      localStorage.clear();
      window.location.reload();
    }
  }
}

export function* getDashboardCountSaga(params) {
  const slug = params?.params?.slug;
  const startDate = params?.params?.startDate;
  const endDate = params?.params?.endDate;

  if (!startDate || !endDate) {
    return;
  }

  try {
    let getDashboardCount = yield axios.get(
      `${env.baseURLDev}/site/dashboardCount?slug=${slug}&start=${startDate}&end=${endDate}`,
      {
        headers: {
          Authorization: `${localStorage.getItem("token")}`,
        },
      }
    );

    if (getDashboardCount.status === 200) {
      yield put({
        type: SET_DASHBOARD_COUNT,
        data: getDashboardCount.data,
      });
      yield put({
        type: SET_MAN_HOURS,
        data: "countLoaded",
      });
    }
  } catch (e) {
    yield put({ type: HIDE_LOADER });
    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}

export function* getAccessSitesSaga() {
  try {
    // let getAccessSitesResponse = yield axios.get(
    //   `${env.baseURLDev}/site/get-access-sites`,
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    if (getAccessSitesResponse.status === 200) {
      yield put({ type: HIDE_LOADER });
      yield put({
        type: SET_ACCESS_SITES,
        data: getAccessSitesResponse.data.data,
      });
    }
  } catch (e) {
    yield put({ type: HIDE_LOADER });
    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}

export function* getCombinedSitesSaga() {
  try {
    // let getCombinedSitesResponse = yield axios.get(
    //   `${env.newBaseURLDev}/site/get-user-sites-details`,
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    if (getCombinedSitesResponse.status === 200) {
      yield put({ type: HIDE_LOADER });
      yield put({
        type: SET_COMBINED_SITES,
        data: getCombinedSitesResponse.data.data,
      });
    }
  } catch (e) {
    yield put({ type: HIDE_LOADER });
    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}

export function* leaveSiteSaga(params) {
  try {
    // let { id } = params.params.site;
    // let leaveSiteResponse = yield axios.post(
    //   `${env.baseURLDev}/site/leave-site`,
    //   { id },
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    // if (leaveSiteResponse.status === 200) {
    //   window.location.reload();
    // }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* revokeAccessToSiteSaga(params) {
  try {
    // let { site_id, user_id, archive } = params.params;
    // // console.log(params.params);
    // let revokeAccessToSiteResponse = yield axios.post(
    //   `${env.baseURLDev}/site/revoke-access`,
    //   { site_id, user_id, archive },
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    // if (revokeAccessToSiteResponse.status === 200) {
    //   window.location.reload();
    // }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* updateSiteAvatarSaga(params) {
  try {
    // let revokeAccessToSiteResponse = yield axios.post(
    //   `${env.baseURLDev}/site/update-avatar`,
    //   params.params,
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    // if (revokeAccessToSiteResponse.status === 200) {
    //   yield put({
    //     type: SHOW_TOAST,
    //     data: { message: "Worskspace avatar updated.", toastType: 1 },
    //   });
    //   setTimeout(() => window.location.reload(), 1000);
    // }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* updateSiteDetailsSaga(params) {
  try {
    console.log(params.params);
    // let updateSiteDetailsResponse = yield axios.post(
    //   `${env.baseURLDev}/site/update-details`,
    //   params.params,
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    // if (updateSiteDetailsResponse.status === 200) {
    //   yield put({
    //     type: SHOW_TOAST,
    //     data: { message: "Worskspace details updated.", toastType: 1 },
    //   });
    //   setTimeout(() => window.location.reload(), 1000);
    // }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* updateManHoursSaga(params) {
  try {
    const toastId = Date.now();

    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Man hours update in progress...",
        toastType: 2,
        toastId,
        autoHide: false,
      },
    });

    yield put({
      type: POST_MAN_HOURS,
    });

    // let updateManHoursResponse = yield axios.post(
    //   `${env.baseURLDev}/site/update-man-hours`,
    //   params.params,
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    // if (updateManHoursResponse.status === 200) {
    //   yield put({
    //     type: SET_MAN_HOURS,
    //     data: updateManHoursResponse?.data?.message,
    //   });

    //   yield put({
    //     type: SHOW_TOAST,
    //     data: {
    //       message: "Worskspace man hours updated.",
    //       toastType: 1,
    //       toastId,
    //     },
    //   });
    // }
  } catch {
    yield put({
      type: ERROR_MAN_HOURS,
    });
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* getWorkspaceManHoursSaga(params) {
  try {
    // let getWorkspaceManHoursResponse = yield axios.get(
    //   `${env.baseURLDev}/site/get-man-hours?slug=${params.params.slug}`,
    //   {
    //     headers: {
    //       authorization: `${localStorage.getItem("token")}`,
    //     },
    //   }
    // );

    // if (getWorkspaceManHoursResponse.status === 200) {
    //   yield put({
    //     type: SET_WORKSPACE_MAN_HOURS,
    //     data: getWorkspaceManHoursResponse.data.data[0],
    //   });
    // }
  } catch {
    yield put({
      type: SHOW_TOAST,
      data: {
        message: "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}
