import { Typography } from "@presto-labs-ai/design-system-new";
import {
  CALL_REFRESH,
  REFRESH_COMPLETE,
  CARD_REMOVED,
  GET_ALL_ACCESS_DASHBOARDS,
  GET_ALL_DASHBOARDS,
  SELECT_FILTERED_WORKSPACE_MOBILE,
  SET_ALL_ACCESS_DASHBOARDS,
  SET_ALL_CARDS,
  SET_ALL_DASHBOARDS,
  SET_ALL_VIEW_CARDS,
  SET_SHARE_KEY,
  UPDATE_CURRENT_WORKSPACE,
  UPDATE_OWNER_FILTER,
  CONFIRM_SHARE_KEY_LOADING,
  SHARE_KEY_LOADING,
} from "../actions/types";

const initialState = {
  selectedDashboard: null,
  allDashboardsLoading: false,
  allDashboards: [],
  allAccessDashboardsLoading: false,
  allAccessDashboards: [],
  selectedOwner: { label: "All Owners", value: 0 },
  allCards: null,
  allViewCards: null,
  mobileFilteredWorkspace: {
    label: <Typography fontSize="base">All Workspaces</Typography>,
    value: 0,
  },
  shareKey: null,
  shareKeyLoading: null,
  confirmShareKeyData: null,
  confirmShareKeyLoading: null,
  refreshAllCards: false,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case CALL_REFRESH:
      return {
        ...state,
        refreshAllCards: true,
      };

    case REFRESH_COMPLETE:
      return {
        ...state,
        refreshAllCards: false,
      };

    case UPDATE_CURRENT_WORKSPACE:
      return {
        ...state,
        selectedDashboard: action.params.dashboard,
      };

    case GET_ALL_DASHBOARDS:
      return {
        ...state,
        allDashboardsLoading: true,
      };

    case SET_ALL_DASHBOARDS:
      return {
        ...state,
        allDashboardsLoading: false,
        allDashboards: action.data.data,
      };

    case GET_ALL_ACCESS_DASHBOARDS:
      return {
        ...state,
        allAccessDashboardsLoading: true,
      };

    case SET_ALL_ACCESS_DASHBOARDS:
      return {
        ...state,
        allAccessDashboardsLoading: false,
        allAccessDashboards: action.data.data,
      };

    case UPDATE_OWNER_FILTER:
      return {
        ...state,
        selectedOwner: action.params,
      };

    case SET_ALL_CARDS:
      return {
        ...state,
        allCards: action.data,
      };

    case CARD_REMOVED:
      let temp = [...state.allCards];

      let index = temp.findIndex((item) => item.id === action.data.id);

      if (index > -1) temp.splice(index, 1);

      return {
        ...state,
        allCards: temp,
      };

    case SELECT_FILTERED_WORKSPACE_MOBILE:
      return {
        ...state,
        mobileFilteredWorkspace: action.params.workspace,
      };

    case SHARE_KEY_LOADING:
      return {
        ...state,
        shareKeyLoading: action.data,
        confirmShareKeyLoading: null,
      };

    case SET_SHARE_KEY:
      return {
        ...state,
        shareKey: action.data,
        shareKeyLoading: false,
      };

    case CONFIRM_SHARE_KEY_LOADING:
      return {
        ...state,
        confirmShareKeyLoading: action.data.loading,
        confirmShareKeyData: action.data.data,
      };

    case SET_ALL_VIEW_CARDS:
      return {
        ...state,
        allViewCards: action.data,
      };

    default:
      return state;
  }
}
