
import { put } from "redux-saga/effects";
import axios from "axios";
import {
  SET_CURRENT_PLAN,
  SET_STRIPE_PLANS,
  SET_STRIPE_SESSION,
  SET_STRIPE_TRANSACTION_STATUS,
  SHOW_TOAST,
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export function* createStripeSessionSaga(params) {
  try {
    let res = yield axios.post(
      `${env.newBaseURLDev}/payment/payments/create_checkout_session/`,
      params.params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (res.status === 200 && res.data.success) {
      localStorage.setItem(
        "lastPayment",
        JSON.stringify({
          session_id: res.data.session_id,
          transaction_id: res.data.transaction_id,
        })
      );
      yield put({
        type: SET_STRIPE_SESSION,
        payload: res.data.session_id,
      });
    } else {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Error in creating stripe session, please try again later.",
          toastType: 0,
        },
      });
    }
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      data: {
        message:
          error?.response?.data?.message ||
          "Something went wrong, please try again later.",
        toastType: 0,
      },
    });
  }
}

export function* getStripeTransactionStatusSaga(params) {
  try {
    const res = yield axios.post(
      `${env.newBaseURLDev}/payment/payments/verify_payment/`,
      params.params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (res.data.success) {
      yield put({
        type: SET_STRIPE_TRANSACTION_STATUS,
        payload: true,
      });
    } else {
      yield put({
        type: SET_STRIPE_TRANSACTION_STATUS,
        payload: false,
      });
    }
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      payload: true,
    });
    yield put({
      type: SET_STRIPE_TRANSACTION_STATUS,
      payload: false,
    });
  }
}

export function* getStripePlansSaga(params) {
  try {
    const plans = yield axios.get(
      `${env.newBaseURLDev}/payment/payments/get_payment_plans/`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    yield put({
      type: SET_STRIPE_PLANS,
      payload: plans.data,
    });
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      payload: true,
    });
  }
}

export function* getCurrentPlanSaga(params) {
  try {
    const plan = yield axios.get(
      `${env.newBaseURLDev}/payment/payments/get_current_active_plan/?workspace_id=${params.params}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    yield put({
      type: SET_CURRENT_PLAN,
      payload: plan.data,
    });
  } catch (error) {
    yield put({
      type: SHOW_TOAST,
      payload: true,
    });
  }
}

export function* getLaunchConfigSaga(params) {
  try {
    let getLaunchConfigResponse = yield axios.get(
      `${env.newBaseURLDev}/payment/payments/get_launch_config/?workspace_id=${
        JSON.parse(localStorage.getItem("new_workspace"))?.id
      }`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    if (getLaunchConfigResponse.status === 200) {
      console.log("getLaunchConfigResponse",getLaunchConfigResponse);
      yield put({
        type: "SET_LAUNCH_CONFIG",
        payload: getLaunchConfigResponse.data?.data,
      });
      yield put({
        type: "SET_USED_CREDITS",
        payload: getLaunchConfigResponse.data?.data?.used_presto_credits,
      });
      yield put({
        type: "SET_TOTAL_CREDITS",
        payload: getLaunchConfigResponse.data?.data?.total_presto_credits,
      });
      if(params.params.onSuccess){
        params.params.onSuccess(getLaunchConfigResponse.data?.data)
      }
    }
  } catch (error) {}
}