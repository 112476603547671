import axios from "axios";
import { put } from "redux-saga/effects";
import jwt_decode from "jwt-decode";
import {
  AUTHENTICATION_FAILED,
  HIDE_LOADER,
  SHOW_TOAST,
} from "../actions/types";

const env = require(`../.config.${process.env.REACT_APP_ENV}.json`);

export const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export function* signUpSaga(params) {
  try {
    let email = params.params.email;
    if (!isValidEmail(email)) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Please enter a valid Email.", toastType: 0 },
      });
    } else if (isValidEmail(email)) {
      let payloadNew = {};
      payloadNew.fullname =
        params.params.fullName;
      payloadNew.email = params.params.email;
      payloadNew.password = params.params.password;
      payloadNew.workspace_invite_slug =
        params.params.workspaceInviteSlug ?? "";
      payloadNew.user_invite_code = params.params.userInviteCode ?? "";
      payloadNew.referral_id = params.params.referralId ?? "";
      payloadNew.phone_number =
        params.params.phonenumber ??
        Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) +
          1000000000 +
          " ";
      payloadNew.country_code = params.params.countryCode ?? "IN";
      payloadNew.org_id = params.params.orgId ?? "";
      payloadNew.sub_id = params.params.sub_id ?? "";
      const newSignUpResponse = yield axios.post(
        `${env.newBaseURLDev}/users/user/auth_zero_signup/`,
        payloadNew,
        {
          headers: {
            Authorization: params.params.accessToken,
          },
        }
      );


      if (newSignUpResponse.status === 201) {
        params.params.onSuccess(newSignUpResponse);

      }
    }
  } catch (e) {
    console.log(e);
    yield put({ type: HIDE_LOADER });
    yield put({ type: AUTHENTICATION_FAILED });
    if (e.message.includes("400")) {
      params.params?.onError(e);
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Account already exists, please Log In.",
          toastType: 0,
        },
      });
    }
    if (e.message.includes("500")) {
      params.params?.onError(e);
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}

export function* logInSaga(params) {
  try {
    if (!isValidEmail(params.params.email)) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Please enter a valid Email.", toastType: 0 },
      });
      yield put({ type: AUTHENTICATION_FAILED });
    } else {
      let payload = {};
      payload.email = params.params.email;
      payload.password = params.params.password;
      const newSignInResponse = yield axios.post(
        `${env.newBaseURLDev}/users/user/login/`,
        payload
      );

      if (newSignInResponse.status === 200) {
        yield put({ type: HIDE_LOADER });
        const decoded = jwt_decode(newSignInResponse?.data?.token);
        // console.log(decoded);
        localStorage.setItem(
          "new_resource_owner",
          JSON.stringify(newSignInResponse?.data?.user)
        );
        localStorage.setItem(
          "new_org_details",
          JSON.stringify(newSignInResponse?.data?.org_details)
        );
        localStorage.setItem(
          "new_workspace",
          JSON.stringify(newSignInResponse?.data?.workspace)
        );
        localStorage.setItem(
          "new_board",
          JSON.stringify(newSignInResponse?.data?.board)
        );
        localStorage.setItem("token", newSignInResponse?.data?.token);

        localStorage.setItem("drawerOpen", true);
        if (decoded?.email_verified === 0) {
          window.location = "/verify-email";
        }

        if (!params.params.redirect) {
          window.location = "/assistant";
        } else {
          // console.log(params.params.redirect);
          window.location = params.params.redirect;
        }
      }
    }
  } catch (e) {
    yield put({ type: HIDE_LOADER });
    yield put({ type: AUTHENTICATION_FAILED });
    if (e.message.includes("404")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Account does not exist. Please create one.",
          toastType: 0,
        },
      });
    }

    if (e.message.includes("401")) {
      yield put({
        type: SHOW_TOAST,
        data: { message: "Incorrect Email or Password.", toastType: 0 },
      });
    }

    if (e.message.includes("500")) {
      yield put({
        type: SHOW_TOAST,
        data: {
          message: "Something went wrong, please try again later.",
          toastType: 0,
        },
      });
    }
  }
}


export function* checkInvitedOrganisationsSaga(params) {
  const checkInvitedOrganisationsResponse = yield axios.get(
    `${env.newBaseURLDev}/users/referral/get_all_referrals/?email=${params.params.email}`,
    {
      headers: {
        Authorization: `Berear ${params.params.token}`,
      },
    }
  );
    if (checkInvitedOrganisationsResponse.status === 200) {
      params.params.onSuccess(checkInvitedOrganisationsResponse?.data);
  }
}